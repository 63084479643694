<script setup lang="ts">

import Chart from 'chart.js/auto'
import { ref, onMounted } from 'vue'

const props = defineProps<{
    label: string
    labels: []
    data: []
}>()

const canvas = ref('')

onMounted(() => {
    new Chart(
        canvas.value,
        {
            type: 'bar',
            data: {
                labels: props.labels,
                datasets: [
                    {
                        backgroundColor: 'rgba(151,187,205,0.5)',
                        borderColor: 'rgba(151,187,205,1)',
                        borderWidth: 1,
                        label: 'Price',
                        data: props.data
                    }
                ]
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true,
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function (value) {
                                return value !== 0 ? ('$' + value) : value
                            }
                        }
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                let label = context.dataset.label || ''

                                if (label) {
                                    label += ': '
                                }
                                if (context.parsed.y !== null) {
                                    label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y)
                                }
                                return label
                            }
                        }
                    },
                    legend: {
                        display: false
                    }
                }
            }
        }
    )
})

</script>

<template>
    <canvas
        ref="canvas"
        role="img"
        :aria-label="label"
    >{{ label }}
    </canvas>
</template>

<style scoped lang="scss">

</style>